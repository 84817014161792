<template>
    <div>
        <el-dialog
                v-model="seeVisible"
                width="75%"
                :fullscreen="isFullscreen"
                :before-close="handleClose"
                @close="close"
                @open="open"
            >
                <div style="cursor: pointer;position: absolute;top:0.06rem;right: 0.5rem;" @click="fullScreen">
                    <el-icon>
					    <FullScreen />
				    </el-icon>
                    <!-- {{isFullscreen ? '关闭全屏' : '全屏'}} -->
                </div>
                <div class="dialogBox" v-if="track != '教师'">
                    <el-tabs v-model="activeName" type="border-card" class="demo-tabs" @tab-click="handleClick">
                        <el-tab-pane label="信息通信工程实践" name="1" style="position: relative;">
                            <el-table @cell-click="handleDbClick1" :row-class-name="tableRowClassName1"
                            :cell-class-name="tableCellClassName1" :height="tableHeight"  v-loading="loading" border :scrollbar-always-on="true" :data="tableData"
                            style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" align="center" />
                            <el-table-column prop="school" sortable label="学校全称" width="180" align="center" />
                            <el-table-column prop="province" sortable label="省份" align="center" />
                            <el-table-column prop="group" sortable label="组别" width="120" align="center" />
                            <!-- <el-table-column :key="index" v-for="(item,index) in this.tableData.players" :prop="item.name" sortable :label="item.lable" width="180" /> -->
                            <el-table-column prop="name1" sortable label="参赛选手1" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.name1" @change="handChange(scope.row.member_id1,'name',$event.trim(),'参赛选手1')" size="small"/>
                                        <span v-else>{{scope.row.name1}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id_num1" sortable label="身份证号" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.id_num1" @change="handChange(scope.row.member_id1,'id_num',$event.trim(),'身份证号')" size="small"/>
                                        <span v-else>{{scope.row.id_num1}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="phone1" sortable label="手机号" width="140" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.phone1" @change="handChange(scope.row.member_id1,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone1}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="mail1" sortable label="邮箱" width="180" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.mail1" @change="handChange(scope.row.member_id1,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail1}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="sex1" sortable label="性别" width="120" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-select
                                            :ref="'tab'+scope.row.index+scope.column.index" 
                                            v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]"
                                            v-model="scope.row.sex1"
                                            placeholder="Select"
                                            style="width: 80px"
                                            @change="handChange(scope.row.member_id1,'sex',$event.trim(),'性别')"
                                            >
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                        <span v-else>{{scope.row.sex1}}</span>
                                    </div>
                                </template>
                            </el-table-column>                            
                            <el-table-column prop="grade1" sortable label="学级" width="120" align="center">
                            </el-table-column>                            
                            <el-table-column prop="profession1" sortable label="专业名称" width="120" align="center">
                            </el-table-column>                                
                            <el-table-column prop="name2" sortable label="参赛选手2" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.name2" @change="handChange(scope.row.member_id2,'name',$event.trim(),'参赛选手2')" size="small"/>
                                        <span v-else>{{scope.row.name2}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id_num2" sortable label="身份证号" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.id_num2" @change="handChange(scope.row.member_id2,'id_num',$event.trim(),'身份证号')" size="small"/>
                                        <span v-else>{{scope.row.id_num2}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                             <el-table-column prop="phone2" sortable label="手机号" width="140" align="center">
                                <template #default="scope">
                                        <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.phone2" @change="handChange(scope.row.member_id2,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone2}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                             <el-table-column prop="mail2" sortable label="邮箱" width="180" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.mail2" @change="handChange(scope.row.member_id2,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail2}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="sex2" sortable label="性别" width="120" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-select
                                            :ref="'tab'+scope.row.index+scope.column.index" 
                                            v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]"
                                            v-model="scope.row.sex2"
                                            placeholder="Select"
                                            style="width: 80px"
                                            @change="handChange(scope.row.member_id2,'sex',$event.trim(),'性别')"
                                            >
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                        <span v-else>{{scope.row.sex2}}</span>
                                    </div>
                                </template>
                            </el-table-column>                                                           
                           <el-table-column prop="grade2" sortable label="学级" width="120" align="center">
                            </el-table-column>                              
                           <el-table-column prop="profession2" sortable label="专业名称" width="120" align="center">
                            </el-table-column>                              
                            <el-table-column prop="name3" sortable label="指导教师1" width="180" align="center">
                                <template #default="scope">
                                        <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.name3" @change="handChange(scope.row.member_id3,'name',$event.trim(),'指导教师1')" size="small"/>
                                        <span v-else>{{scope.row.name3}}</span>
                                    </div>
                                </template>
                            </el-table-column>                            
                            <el-table-column prop="phone3" sortable label="手机号" width="140" align="center">
                                <template #default="scope">
                                        <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.phone3" @change="handChange(scope.row.member_id3,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone3}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                             <el-table-column prop="mail3" sortable label="邮箱" width="180" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.mail3" @change="handChange(scope.row.member_id3,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail3}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name4" sortable label="指导教师2" width="180" align="center">
                                <template #default="scope">
                                        <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.name4" @change="handChange(scope.row.member_id4,'name',$event.trim(),'指导教师2')" size="small"/>
                                        <span v-else>{{scope.row.name4}}</span>
                                    </div>
                                </template>
                            </el-table-column>                            
                            <el-table-column prop="phone4" sortable label="手机号" width="140" align="center">
                                <template #default="scope">
                                        <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.phone4" @change="handChange(scope.row.member_id4,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone4}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="mail4" sortable label="邮箱" width="180" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.mail4" @change="handChange(scope.row.member_id4,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail4}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name5" sortable label="带队教师" width="180" align="center">
                                <template #default="scope">
                                        <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.name5" @change="handChange(scope.row.member_id5,'name',$event.trim(),'带队教师')" size="small"/>
                                        <span v-else>{{scope.row.name5}}</span>
                                    </div>
                                </template>
                            </el-table-column>                           
                            <el-table-column prop="phone5" sortable label="手机号" width="180" align="center">
                                <template #default="scope">
                                        <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]" 
                                         v-model="scope.row.phone5" @change="handChange(scope.row.member_id5,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone5}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="产教融合5G+创新应用设计赛" name="2" style="position: relative;">
                            <el-table @cell-click="handleDbClick2" :row-class-name="tableRowClassName2" :cell-class-name="tableCellClassName2" :height="tableHeight" v-loading="innovateLoading" border :scrollbar-always-on="true" :data="tableData2" style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" align="center" />
                            <el-table-column prop="school" sortable label="学校全称" width="200" align="center" />
                            <el-table-column prop="province" sortable label="学校所在省份"  width="140" align="center"/>
                            <el-table-column prop="name" sortable label="队名" width="180" align="center"/>
                            <el-table-column prop="role" sortable label="角色" width="180" align="center"/>
                            <el-table-column prop="name1" sortable label="姓名" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex2[0]&& scope.column.index === activeCellIndex2[1]" 
                                         v-model="scope.row.name1" @change="handChange(scope.row.member_id,'name',$event.trim(),'姓名')"  size="small"/>
                                        <span v-else>{{scope.row.name1}}</span>
                                    </div>
                                </template>
                            </el-table-column>                            
                            <el-table-column prop="id_num" sortable label="身份证号" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex2[0]&& scope.column.index === activeCellIndex2[1]" 
                                         v-model="scope.row.id_num" @change="handChange(scope.row.member_id,'id_num',$event.trim(),'身份证号')" size="small"/>
                                        <span v-else>{{scope.row.id_num}}</span>
                                    </div>
                                </template>
                            </el-table-column>                                
                            <el-table-column prop="phone" sortable label="手机号" width="140" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex2[0]&& scope.column.index === activeCellIndex2[1]" 
                                         v-model="scope.row.phone" @change="handChange(scope.row.member_id,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone}}</span>
                                    </div>
                                </template>
                            </el-table-column>                              
                            <el-table-column prop="mail" sortable label="邮箱" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex2[0]&& scope.column.index === activeCellIndex2[1]" 
                                         v-model="scope.row.mail" @change="handChange(scope.row.member_id,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail}}</span>
                                    </div>
                                </template>
                            </el-table-column>                             
                            <el-table-column prop="sex" sortable label="性别" width="120" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-select
                                            :ref="'tab'+scope.row.index+scope.column.index" 
                                            v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]"
                                            v-model="scope.row.sex"
                                            placeholder="Select"
                                            style="width: 80px"
                                            @change="handChange(scope.row.member_id,'sex',$event.trim(),'性别')"
                                            >
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                        <span v-else>{{scope.row.sex}}</span>
                                    </div>
                                </template>
                            </el-table-column>                            
                            <el-table-column prop="grade" sortable label="学级" width="120" align="center">
                            </el-table-column>                                                                                       
                            <el-table-column prop="nation" sortable label="国籍" width="180" align="center" />
                            <el-table-column prop="college" sortable label="院系" width="180" align="center" />
                            <el-table-column prop="profession" sortable label="专业名称" width="180" align="center">
                            </el-table-column>                        
                    </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="ICT基础通识赛" name="3" style="position: relative;">
                            <el-table @cell-click="handleDbClick3" :row-class-name="tableRowClassName3" :cell-class-name="tableCellClassName3"  :height="tableHeight"  v-loading="loading" border :scrollbar-always-on="true" :data="tableData3"
                            style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                            <el-table-column prop="school" sortable label="学校全称" width="120" />
                            <el-table-column prop="province" sortable label="省份" />
                            <el-table-column prop="name1" sortable label="参赛选手1" width="200">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.name1" @change="handChange(scope.row.member_id1,'name',$event.trim(),'参赛选手1')" size="small"/>
                                        <span v-else>{{scope.row.name1}}</span>
                                    </div>
                                </template>
                            </el-table-column>                               
                            <el-table-column prop="id_num1" sortable label="身份证号" width="180" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.id_num1" @change="handChange(scope.row.member_id1,'id_num',$event.trim(),'身份证号')" size="small"/>
                                        <span v-else>{{scope.row.id_num1}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="phone1" sortable label="手机号" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.phone1" @change="handChange(scope.row.member_id1,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone1}}</span>
                                    </div>
                                </template>
                            </el-table-column>   
                            <el-table-column prop="mail1" sortable label="邮箱" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.mail1" @change="handChange(scope.row.member_id1,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail1}}</span>
                                    </div>
                                </template>
                            </el-table-column>   
                            <el-table-column prop="sex1" sortable label="性别" width="120" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-select
                                            :ref="'tab'+scope.row.index+scope.column.index" 
                                            v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]"
                                            v-model="scope.row.sex1"
                                            placeholder="Select"
                                            style="width: 80px"
                                            @change="handChange(scope.row.member_id1,'sex',$event.trim(),'性别')"
                                            >
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                        <span v-else>{{scope.row.sex1}}</span>
                                    </div>
                                </template>
                            </el-table-column>   
                            <el-table-column prop="grade1" sortable label="学级" width="120" align="center">    
                            </el-table-column>     
                            <el-table-column prop="profession1" sortable label="专业名称" width="120" align="center">
                            </el-table-column>                                                           
                            <el-table-column prop="name2" sortable label="参赛选手2" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.name2" @change="handChange(scope.row.member_id2,'name',$event.trim(),'参赛选手2')" size="small"/>
                                        <span v-else>{{scope.row.name2}}</span>
                                    </div>
                                </template>
                            </el-table-column>                              
                            <el-table-column prop="id_num2" sortable label="身份证号" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.id_num2" @change="handChange(scope.row.member_id2,'id_num',$event.trim(),'身份证号')" size="small"/>
                                        <span v-else>{{scope.row.id_num2}}</span>
                                    </div>
                                </template>
                            </el-table-column>                     
                             <el-table-column prop="phone2" sortable label="手机号" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.phone2" @change="handChange(scope.row.member_id2,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone2}}</span>
                                    </div>
                                </template>
                            </el-table-column>                            
                             <el-table-column prop="mail2" sortable label="邮箱" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.mail2" @change="handChange(scope.row.member_id2,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail2}}</span>
                                    </div>
                                </template>
                            </el-table-column> 
                            <el-table-column prop="sex2" sortable label="性别" width="120" align="center">
                                 <template #default="scope">
                                    <div>
                                        <el-select
                                            :ref="'tab'+scope.row.index+scope.column.index" 
                                            v-if="scope.row.index === activeCellIndex1[0]&& scope.column.index === activeCellIndex1[1]"
                                            v-model="scope.row.sex2"
                                            placeholder="Select"
                                            style="width: 80px"
                                            @change="handChange(scope.row.member_id2,'sex',$event.trim(),'性别')"
                                            >
                                            <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                        <span v-else>{{scope.row.sex2}}</span>
                                    </div>
                                </template>
                            </el-table-column>                                                                 
                            <el-table-column prop="grade2" sortable label="学级" width="120" align="center">
                                </el-table-column>                                
                           <el-table-column prop="profession2" sortable label="专业名称" width="120" align="center">
                            </el-table-column>    
                            <el-table-column prop="name3" sortable label="指导教师1" width="200" align="center">
                                    <template #default="scope">
                                        <div>
                                            <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                            v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                            v-model="scope.row.name3" @change="handChange(scope.row.member_id3,'name',$event.trim(),'指导教师1')" size="small"/>
                                            <span v-else>{{scope.row.name3}}</span>
                                        </div>
                                    </template>
                                </el-table-column>                             
                            <el-table-column prop="phone3" sortable label="手机号" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.phone3" @change="handChange(scope.row.member_id3,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone3}}</span>
                                    </div>
                                </template>
                            </el-table-column>                             
                            <el-table-column prop="mail3" sortable label="邮箱" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.mail3" @change="handChange(scope.row.member_id3,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail3}}</span>
                                    </div>
                                </template>
                            </el-table-column>                                                        
                            <el-table-column prop="name4" sortable label="指导教师2" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.name4" @change="handChange(scope.row.member_id4,'name',$event.trim(),'指导教师2')" size="small"/>
                                        <span v-else>{{scope.row.name4}}</span>
                                    </div>
                                </template>
                            </el-table-column>                             
                            <el-table-column prop="phone4" sortable label="手机号" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.phone4" @change="handChange(scope.row.member_id4,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone4}}</span>
                                    </div>
                                </template>
                            </el-table-column>                                 
                            <el-table-column prop="mail4" sortable label="邮箱" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.mail4" @change="handChange(scope.row.member_id4,'mail',$event.trim(),'邮箱')" size="small"/>
                                        <span v-else>{{scope.row.mail4}}</span>
                                    </div>
                                </template>
                            </el-table-column>     
                            <el-table-column prop="name5" sortable label="带队教师" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.name5" @change="handChange(scope.row.member_id5,'name',$event.trim(),'带队教师')" size="small"/>
                                        <span v-else>{{scope.row.name5}}</span>
                                    </div>
                                </template>
                            </el-table-column>                             
                            <el-table-column prop="phone5" sortable label="手机号" width="200" align="center">
                                <template #default="scope">
                                    <div>
                                        <el-input :ref="'tab'+scope.row.index+scope.column.index" 
                                         v-if="scope.row.index === activeCellIndex3[0]&& scope.column.index === activeCellIndex3[1]" 
                                         v-model="scope.row.phone5" @change="handChange(scope.row.member_id5,'phone',$event.trim(),'手机号')" size="small"/>
                                        <span v-else>{{scope.row.phone5}}</span>
                                    </div>
                                </template>
                            </el-table-column>                             
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- <p  style="margin-bottom: 0.1rem;">信息通信工程实践</p> -->
                </div>
                <div class="dialogBox" v-if="track == '教师'">
                    <el-tabs v-model="activeName" type="border-card" class="demo-tabs" @tab-click="handleClick">
                        <el-tab-pane label="信息通信工程实践" name="1" style="position: relative;">
                            <el-table  v-loading="loading" border :scrollbar-always-on="true" :data="tableData"
                            style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                        <el-table-column prop="school" sortable label="学校全称" width="120" />
                        <el-table-column prop="province" sortable label="省份" />
                        <el-table-column prop="group" sortable label="组别" width="180" />

                        <el-table-column prop="name1" sortable label="参赛选手" width="180" />
                        <el-table-column prop="id_num1" sortable label="身份证号" width="180" />
                        <el-table-column prop="phone1" sortable label="手机号" width="180" />
                        <el-table-column prop="mail1" sortable label="邮箱" width="180" />
                        <el-table-column prop="name5" sortable label="带队教师" width="180" />
                        <el-table-column prop="phone5" sortable label="手机号" width="180" />
                            </el-table>
                        </el-tab-pane>
                        
                    </el-tabs>
                    <!-- <p  style="margin-bottom: 0.1rem;">信息通信工程实践</p> -->
                </div>
                <!-- <div class="dialogBox">
                    <p style="margin-bottom: 0.1rem;">5G+创新应用实践赛</p>
                    <el-table v-loading="innovateLoading" border :data="tableData2" style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                            <el-table-column prop="school" sortable label="学校全称" width="120" />
                            <el-table-column prop="province" sortable label="学校所在省份"  width="140"/>
                            <el-table-column prop="name" sortable label="队名" width="180" />
                            <el-table-column prop="role" sortable label="角色" width="180" />
                            <el-table-column prop="name" sortable label="姓名" width="180" />
                            <el-table-column prop="id_num" sortable label="身份证号" width="180" />
                            <el-table-column prop="phone" sortable label="手机号" width="180" />
                            <el-table-column prop="mail" sortable label="邮箱" width="180" />
                            <el-table-column prop="sex" sortable label="性别" width="180" />
                            <el-table-column prop="grade" sortable label="学级" width="180" />
                            <el-table-column prop="profession" sortable label="专业名称" width="180" />

                            
                    </el-table>
                </div> -->
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="saveData">保存</el-button>
                        <el-button @click="handleClose">关闭</el-button>
                    </span>
                    </template>
            </el-dialog>
    </div>
</template>
<script>
import { userInfo } from "api/apis.js";
import { FullScreen } from '@element-plus/icons-vue'
import { ElMessage } from "element-plus";
import { stringify } from 'qs';
export default {
    // props:['seeVisible'],
    components:{
        FullScreen
    },
    data() {
        return {
            activeName:'1',
            seeVisible:false,
            isFullscreen:false,
            tableHeight:400,
            tableData:[],
            tableData2:[],
            tableData3:[],
            payment_id:'',
            loading:false,
            innovateLoading:false,
            track:'',
            activeCellIndex1:[],
            activeCellIndex2:[],
            activeCellIndex3:[],
            originallyTableData1:[],
            originallyTableData2:[],
            originallyTableData3:[],
            tabIndex:'1',
            list1:[],
            list2:[],
            list3:[],
            result1:{},
            result2:{},
            result3:{},
            options:[
                {label:'男',value:'男'},
                {label:'女',value:'女'}
            ]
        }
    },
    created() {
        
    },
    methods:{
        handleDbClick1(row, column){
            this.activeCellIndex1 = [row.index, column.index];
            this.$nextTick(() => {
                this.$refs['tab'+row.index+column.index].focus()
            })
        },
        tableRowClassName1({ row, rowIndex }) {
            row.index = rowIndex;
        },
        tableCellClassName1({ column, columnIndex }) {
            column.index = columnIndex;
        },
        handleDbClick2(row, column){ 
            this.activeCellIndex2 = [row.index, column.index];
            this.$nextTick(() => {
                this.$refs['tab'+row.index+column.index].focus()
            })
        },
        tableRowClassName2({ row, rowIndex }) {
            row.index = rowIndex;
        },
        tableCellClassName2({ column, columnIndex }) {
            column.index = columnIndex;  
        },
        handleDbClick3(row, column){
            this.activeCellIndex3 = [row.index, column.index];
            this.$nextTick(() => {
                this.$refs['tab'+row.index+column.index].focus()
            })
        },
        tableRowClassName3({ row, rowIndex }) {
            row.index = rowIndex;
        },
        tableCellClassName3({ column, columnIndex }) {
            column.index = columnIndex;
        },
        handChange(member_id,name,event,lable){
            if (!event) {
                ElMessage({
                    message:lable + "数据不能为空!",
                    type: "error",
                });            
            }
            let tempObj = {};           
            let obj = {}
            obj[name]=event
            tempObj[member_id] = obj
            if (this.tabIndex == '1') {        
                this.list1.push(tempObj)
                this.list1.forEach(obj => {
                    for (let key in obj) {
                        if (this.result1[key]) {
                            this.result1[key] = { ...this.result1[key] , ...obj[key]};
                        } else {
                            this.result1[key] = obj[key];
                        }
                    }
                });  
            }else if (this.tabIndex == '2') {                 
                this.list2.push(tempObj)
                this.list2.forEach(obj => {
                    for (let key in obj) {
                        if (this.result2[key]) {
                            this.result2[key] = { ...this.result2[key] , ...obj[key]};
                        } else {
                            this.result2[key] = obj[key];
                        }
                    }
                });  
            }else if (this.tabIndex == '3') {                      
                this.list3.push(tempObj)
                this.list3.forEach(obj => {
                    for (let key in obj) {
                        if (this.result3[key]) {
                            this.result3[key] = { ...this.result3[key] , ...obj[key]};
                        } else {
                            this.result3[key] = obj[key];
                        }
                    }
                });  
            }
       },
        saveData(){
            let dataobj = new FormData();
            let uid = this.$store.state.User.user_id
            let track = ''
            let editList = {}
            let flag =''
            if (this.tabIndex == '1') {
                track = '学生'
                editList = this.result1
                flag = '信息通信工程实践'               
            }else if(this.tabIndex == '2'){
                track = '创新'
                editList = this.result2
                flag = '产教融合5G+创新应用设计赛'
            }else if(this.tabIndex == '3'){
                track = 'ICT基础通识'
                editList = this.result3
                flag = 'ICT基础通识赛'
            }
            if(Object.keys(editList).length === 0){
                ElMessage({
                    message:"无修改数据!",
                        type: "warning",
                    });
                return
            }
            if(Object.keys(editList).length != 0 && Object.values(editList).length != 0){
                for (let key in editList) {
                    for (let j in editList[key]) {
                        if (editList[key].hasOwnProperty(j) && editList[key][j] === '') {
                            ElMessage({
                                message:"数据不能为空!",
                                type: "warning",
                            });
                            return;
                        }
                    }
                }
            }
            dataobj.append("user_id", uid);
            dataobj.append("type", '省赛');
            dataobj.append("track", track);
            dataobj.append("new_info", JSON.stringify(editList));
            userInfo.infoUpdate(dataobj).then(res=>{
                if (res.code == '200') {
                    if (this.tabIndex == '1') {
                        this.result1 = {}
                        this.list1 = []
                        this.activeCellIndex1 = []
                    }else if(this.tabIndex == '2'){
                       this.result2 = {}
                       this.list2 = []
                       this.activeCellIndex2 = []
                    }else if(this.tabIndex == '3'){
                        this.result3 = {}
                        this.list3 = []
                        this.activeCellIndex3 = []
                    }
                    this.getUserNumberss()
                    ElMessage({
                        message:flag + res.msg,
                        type: "success",
                    });
                }else{
                    ElMessage({
                        message: res.msg,
                        type: "error",
                    });
                }
            })
        },
        onFormClick(activeName,oldActiveName){       
            return new Promise((resolve, reject) => {
                if ((oldActiveName == '1'&& Object.keys(this.result1).length != 0) ||
                   (oldActiveName == '2'&& Object.keys(this.result2).length != 0) ||
                    (oldActiveName == '3'&& Object.keys(this.result3).length != 0) 
                ) {               
                    this.$confirm('有未保存数据，确认切换?')
                    .then(_ => {
                         resolve();
                    })
                    .catch(_ => {
                        // reject();
                    });
                }else{
                    resolve();
                }
            });
        },
        handleClick(name,event) {           
            this.tabIndex = name.props.name;
        },
        //打开时的回调
        open(rows) {
            this.seeVisible = true
            if (rows){
                this.payment_id = rows.payment_id;
                this.result1 = {} 
                this.result2 = {}
                this.result3 = {}
                this.list1 = []
                this.list2 = []
                this.list3 = []
            }
        },
        //信息通信工程实践
        getUserNumberss() {
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            // dataobj.append("page", this.page);//当前页数
            // dataobj.append("limit", this.pageSize);//一页显示多少条
            dataobj.append("payment_id", this.payment_id);//支付id
            
            this.loading = true;
            this.tableData2 = []
            this.tableData3 = []
            this.tableData = []
            this.team_ids = [];
            this.ictTeam_ids = [];
            userInfo.selsetUser(dataobj).then(res => {
                this.nums= 0;
                this.loading = false;
                const { count, data } = res;
                this.total = count || 0;
                let trackA= []
                let trackB= []
                let trackC= []
                let trackD= []
                JSON.parse(data).forEach(t=> {
                    if(t.track === '创新') {
                        trackA.push(t)
                        this.track = '创新'
                    }else if(t.track === '学生') {
                        trackB.push(t)
                        this.track = '学生'
                    }else if(t.track === '教师') {
                        trackC.push(t)
                        this.track = '教师'
                    }else if(t.track === "ICT基础通识"){
                        trackD.push(t)
                        this.track = 'ICT基础通识'
                    }
                })
                // let totalNums = [] //计算队员总数
                // this.team_nums = +Number(JSON.parse(data).length);
                // JSON.parse(data).forEach(m=> {
                //     totalNums.push(...m.players)
                // })
                
                // this.nums = totalNums.length;
                // let trackType = [...JSON.parse(data)][0].track
                if(trackA.length) {  
                    let leveListP = [];
                    let leveListT = [];
                    trackA.forEach(ele=> {
  
                    //队员信息
                    leveListP=ele.players.map(accItem=> {
                        return {
                            school:ele.school,
                            province:ele.province,
                            name:ele.name,
                            role:accItem.role,
                            name1:accItem.name,
                            id_num:accItem.id_num,
                            phone:accItem.phone,
                            mail:accItem.mail,
                            sex:accItem.sex,
                            grade:accItem.grade,
                            profession:accItem.profession,
                            member_id:accItem.member_id
                        }
                    });
                    //教师信息
                    leveListT=ele.teachers.map(accItem=> {
                        return {
                            school:ele.school,
                            province:ele.province,
                            name:ele.name,
                            role:accItem.role,
                            name1:accItem.name,
                            id_num:accItem.id_num,
                            phone:accItem.phone,
                            mail:accItem.mail,
                            sex:accItem.sex,
                            grade:accItem.grade,
                            profession:accItem.profession,
                            member_id:accItem.member_id
                        }
                    })

                    ele.members.forEach(element=>{
                        leveListP.forEach(e=>{
                            if (element.member_id == e.member_id) {
                                e.nation = element.nation;
                                e.college = element.college;
                            }
                        })
                        leveListT.forEach(e=>{
                            if (element.member_id == e.member_id) {
                                e.nation = element.nation;
                                e.college = element.college;
                            }
                        })
                    })

                    this.tableData2.push(...leveListP);
                    this.tableData2.push(...leveListT);
                    // this.innovateTotal = Number(this.tableData2.length) 
                    });
                } 
                if(trackB.length) {
                    trackB.forEach((ele, index) => {
                    let obj = {}
                    this.team_ids.push(ele.team_id) //保存团队id
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    ele.teachers_adviser.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '3'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '4'] = item[key]
                            }
                        } else if (index === 2) {
                            obj.tphone3 = item.phone
                            obj.tmail3 = item.mail
                        }

                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        } 
                    })
                    this.tableData.push(obj);
                })
                }
                if(trackC.length) {
                    trackC.forEach((ele, index) => {
                    let obj = {}
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        } 
                    })
                    
                    this.tableData.push(obj);
                })
                }
                if(trackD.length) {  
                    trackD.forEach((ele, index) => {
                    let obj = {}
                    this.ictTeam_ids.push(ele.team_id) //保存团队id
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    ele.teachers_adviser.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '3'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '4'] = item[key]
                            }
                        } else if (index === 2) {
                            obj.tphone3 = item.phone
                            obj.tmail3 = item.mail
                        }

                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        } 
                    })
                    this.tableData3.push(obj);
                })
                } 
                this.originallyTableData1 = JSON.parse(JSON.stringify(this.tableData))
                this.originallyTableData2 = JSON.parse(JSON.stringify(this.tableData2))
                this.originallyTableData3 = JSON.parse(JSON.stringify(this.tableData3))
            })
        },
        fullScreen() {
            this.$nextTick(()=> {
                this.isFullscreen = !this.isFullscreen
                this.tableHeight = this.isFullscreen ? 500:400
            })
        },
        handleClose(){    
            let flag1 = ''   
            let flag2 = ''   
            let flag3 = ''   
            if(Object.keys(this.result1).length != 0){
                flag1 = '信息通信工程实践、'
            } 
            if(Object.keys(this.result2).length != 0){
                flag2 = '产教融合5G+创新应用设计赛、'
            }
            if (Object.keys(this.result3).length != 0) {
                flag3 = 'ICT基础通识赛、'
            }
            let flag =  flag1+flag2+flag3
            flag = flag.substring(0, flag.lastIndexOf('、'));
            if (Object.keys(this.result1).length != 0 || Object.keys(this.result2).length != 0 || Object.keys(this.result3).length != 0) {               
                this.$confirm( flag +'有未保存数据，确认关闭?')
                .then(_ => {
                    this.seeVisible = false;
                })
                .catch(_ => {
                });
            }else{
                this.seeVisible = false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    :deep(.el-dialog) {
        // height: 5rem;
        .el-dialog__headerbtn {
            top:-0.1rem;
        }
        .dialogBox {
            // margin-bottom: 0.2rem;
            margin: 0.1rem 0 0.2rem 0;
        }
        .el-dialog__footer {

        }
        .el-button{
            width: 1.71rem !important;
            height: 0.45rem !important;
            font-size: 0.24rem !important;
            color: white !important;
            background-color: #436E8A !important; 
        }
        .el-button:first-child{
            width: 1.71rem !important;
            height: 0.45rem !important;
            font-size: 0.24rem !important;
            color: white !important;
            background-color: #2A9FEB !important; 
        }
    }
    :deep(.el-dialog__header) {
        padding: 0 !important;
    }
    :deep(.el-dialog__footer) {
        text-align: center;
    }
    
</style>